@import url('https://fonts.googleapis.com/css2?family=Salsa&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root {
  --tw-bg-opacity: 0.5; /* Adjust the opacity as needed */
}



*{
  overflow-x: none !important;
 
}

body {
  overflow-x: none !important;
 background-color: #ffffff !important;
}
@font-face {
  font-family: 'YourCustomFont';
  src: url('./Fonts/Double_Bubble_shadow.otf') format('opentype');
  /* You can also specify font-weight, font-style, etc. */
}
@font-face {

  src: url('./Fonts/Radley-Sans.ttf') format('opentype');
  /* You can also specify font-weight, font-style, etc. */
}



.backoftop{
  background-color: #a9702a;
  padding: 10px;
}
.colorxbatcat{
  color: #ffffff;
}

.backofdesk {

  height: 100%;
  background-image: url('./andy.svg');
  background-repeat: no-repeat;
  background-size: cover; /* Adjust as needed */
}

.backofdesks{
  background-color: #222831;
  height: 100%;
}

.centeritall{
  justify-content: center;
  align-items: center;
  display: flex;
}

.topimg{
  width: 60%;
  border: 7px solid #000000;
  border-radius:  100px 10px 10px 10px;
}
.topimgm{
  width: 80% !important;
  border: 7px solid #000000;
  border-radius:  100px 10px 10px 10px;
}

.maintext{
  font-family: 'YourCustomFont';
  text-align: center;
  color: #ffffff;
  font-size: 200px !important;
}
.maintextmx{
  font-weight: 300;
  font-family: 'YourCustomFont';
  text-align: center;
  color: #ffffff;
  font-size: 120px !important;
}
.maintextp{
  font-family: "Poppins", sans-serif !important;
  text-align: center;
  color:#2e7a00;
  font-weight: 700;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.695);
  font-size: 60px !important;
}
.maintextpm{
  font-family: "Poppins", sans-serif !important;
  text-align: center;
  color:#2e7a00;
  font-weight: 700;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.695);
  font-size: 40px !important;
}
.maintextos{
  text-align: start;
  color: #ffffff;
  font-family: 'YourCustomFont';
  font-weight: 900px;
  text-align: center;
  font-size: 150px !important;
}

.maintextost{
  text-align: start;
  color: #000000;
text-align: center;
  font-size: 60px !important;
}
.maintextm{
  text-align: center;
  color: #ffffff;
  font-size: 60px !important;
}

.centerDCa{
  text-align: center;
  font-family: 'YourCustomFont';
  color: #ffffff;
  position: relative;
  font-size: 30px;
  top: 20px;
}

.buttonBuy{
  position: relative;
  top: 20px;
  right: 20px;
  align-items: end;
  text-align: end;
  display: ruby;
}
.buttonBuym{
  position: relative;
  top: 20px;
  left: 5px;
  align-items: end;
  text-align: end;

}

.socials{
  color: #ffffff;
  font-size: 45px !important;
}
.socialsm{
  color: #ffffff;
  font-size: 35px !important;
}

.coloroffooter{
  background-color: #2e7a00;
}

.centercaf{
  color: #000000;
  text-align: center;
  font-size: 12px !important;
}

.bizlogo{
  font-family: 'YourCustomFont';
  font-size: 50px !important;
  color: #ffffff;
  position: relative;
  left: 20px;
}


.maintextoss{
  text-align: start;
  color: #000000;
  font-weight: 900px;
  text-align: center;
  font-size: 70px !important;
}

.maintextosts{
  text-align: start;
  color: #000000;
text-align: center;
  font-size: 30px !important;
}

.maintextostsm{
  text-align: start;
  color: #000000;
text-align: center;
  font-size: 20px !important;
}



















.card {
  width: 100%;
  height: 100%;
  justify-content: center;
  text-align: center;
  align-items: center;
  z-index: 10 !important;
  background: rgba(255, 255, 255, 0.996)important;
  border-radius: 10px;
  transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
 }
 
 .shadow {
  box-shadow: inset 0 -3em 3em rgb(212, 6, 6)
              0 0  0 2px rgb(211, 0, 0),
              0.3em 0.3em 1em rgb(255, 0, 0) !important;
 }
 

 @media screen and (min-width: 1000px) {
                  
                          
  .card {
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    border-radius: 10px;
    transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
   }
   
   .shadow {
    box-shadow: inset 0 -3em 3em rgb(212, 6, 6)
                0 0  0 2px rgb(211, 0, 0),
                0.3em 0.3em 1em rgb(255, 0, 0) !important;
   }
      }












      

      .htb_h{
        font-size: 24px !important;
        font-weight: 900;
        color: #000 !important;
       }
       @media screen and (min-width: 1000px){
        .htb_h{
          font-size: 30px !important;
          color: #000 !important;
         }
      }
      
       
       .htb_hp{
        font-size: 15px !important;
        font-weight: 500;
        color: #000 !important;
       }
       @media screen and (min-width: 1000px){
        .htb_hp{
          font-size: 29px !important;
         }
      }



      .maintextosx{
        font-family: 'YourCustomFont';
        text-align: center;
        color: #ffffff;
   
        font-weight: 900px;
        text-align: center;
        font-size: 70px !important;
      }
      
      @media screen and (min-width: 1000px){
      
        .maintextosx{
          text-align: start;
       
          font-weight: 900px;
          text-align: center;
          font-size: 150px !important;
        }
        
      }


      .howbvg{
        background-color:  #509624;
      }









































/* CSS */
.button-3 {
  appearance: none;
  font-family: "Poppins", sans-serif !important;
  background-color: #2e7a00;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
font-weight: 600 !important;
  font-size: 20px;

  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}

.button-3:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.button-3:hover {
  background-color: #2c974b;
}

.button-3:focus {
  box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
  outline: none;
}

.button-3:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
}

.button-3:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}

.colorofcontract{
  background-color: #2e7a00;
}


.cadivs{
  text-align: center;
  padding: 20px;
  background-color: #509624;
  border: 2px solid #000000;
  border-radius: 10px;
}
.hca{
  color:#000000;
  font-weight: 700;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.695);
}
.hcams{
  color:#000000;
  font-size: 12px !important;
  font-weight: 300;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(0, 0, 0, 0.695);
}




































/* CSS */
.button-33 {
  appearance: none;
  font-family: "Poppins", sans-serif !important;
  background-color: #569032;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;

  font-size: 20px;

  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}

.button-33:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.button-33:hover {
  background-color: #2c974b;
}

.button-33:focus {
  box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
  outline: none;
}

.button-33:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
}

.button-33:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}





























.maintextosmx{
  text-align: start;
  color: #ffffff;
  font-family: 'YourCustomFont';
  font-weight: 900px;
  text-align: center;
  font-size: 50px !important;
}



















img.vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
img.vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
@-webkit-keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}
@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}







.vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
.vert-move {
  -webkit-animation: mover 1s infinite  alternate;
  animation: mover 1s infinite  alternate;
}
@-webkit-keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}
@keyframes mover {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}
























































.glowing {
 


 
}

.glowing:nth-child(even) {
  transform-origin: left;
}

@keyframes colorChange {
  0% {
    filter: hue-rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
    transform: rotate(360deg);
  }
}

.glowing span {
  position: absolute;
  top: calc(80px * var(--i));
  left: calc(80px * var(--i));
  bottom: calc(80px * var(--i));
  right: calc(80px * var(--i));
}

.glowing span::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -8px;
  width: 15px;
  height: 15px;
  background: #f00;
  border-radius: 50%;
}

.glowing span:nth-child(3n + 1)::before {
  background: rgb(65, 121, 0);
  box-shadow: 0 0 20px rgb(70, 130, 1),
    0 0 40px rgb(62, 116, 0),
    0 0 60px rgb(57, 106, 0),
    0 0 80px rgb(72, 136, 0),
    0 0 0 8px rgba(134,255,0,.1);
}

.glowing span:nth-child(3n + 2)::before {
  background: rgb(65, 121, 0);
  box-shadow: 0 0 20px rgb(70, 130, 1),
    0 0 40px rgb(62, 116, 0),
    0 0 60px rgb(57, 106, 0),
    0 0 80px rgb(72, 136, 0),
    0 0 0 8px rgba(134,255,0,.1);
}

.glowing span:nth-child(3n + 3)::before {
  background: rgb(65, 121, 0);
  box-shadow: 0 0 20px rgb(70, 130, 1),
    0 0 40px rgb(62, 116, 0),
    0 0 60px rgb(57, 106, 0),
    0 0 80px rgb(72, 136, 0),
    0 0 0 8px rgba(134,255,0,.1);
}

.glowing span:nth-child(3n + 1) {
  animation: animate 10s alternate infinite;
}

.glowing span:nth-child(3n + 2) {
  animation: animate-reverse 3s alternate infinite;
}

.glowing span:nth-child(3n + 3) {
  animation: animate 8s alternate infinite; 
}

@keyframes animate {
  0% {
    transform: rotate(360deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-reverse {
  0% {
    transform: rotate(360deg);
  }
  
  50% {
    transform: rotate(180deg);
  }
  
  100% {
    transform: rotate(0deg);
  }
}


















.maintextosxc{
  font-family: 'YourCustomFont';
  text-align: center;
  color: #ffffff;

  font-weight: 900px;
  text-align: center;
  font-size: 50px !important;
}

@media screen and (min-width: 1000px){

  .maintextosxc{
    text-align: start;
 
    font-weight: 900px;
    text-align: center;
    font-size: 80px !important;
  }
  
}



























.custom-shape-divider-top-1713790348 {
  background-color: #ffffff00;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;

}

.custom-shape-divider-top-1713790348 svg {
  position: relative;
  display: block;
  top: 2px;
  position: relative;
  width: calc(100% + 1.3px);
  height: 62px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1713790348 .shape-fill {
  fill: #2E7A00;
}





.custom-shape-divider-top-1713790199 {
  background-color: #ffffff00;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1713790199 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 73px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1713790199 .shape-fill {
  fill: #2E7A00;
}























































/* CSS */
.button-3 {
  appearance: none;
  font-family: "Poppins", sans-serif !important;
  background-color: #2e7a00;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-weight: 600 !important;
  font-size: 20px;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden; /* Ensure overflow is hidden for the animation */
}

.button-3:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.button-3:hover {
  background-color: #2c974b;
}

.button-3:focus {
  box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
  outline: none;
}

.button-3:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
}

.button-3:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}

/* Add pulsing animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.button-3::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2c974b; /* Adjust color as needed */
  border-radius: inherit;
  animation: pulse 2s ease-in-out infinite; /* Adjust animation duration and timing function as needed */
  z-index: -1;
}
